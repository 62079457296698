import { Injectable } from '@angular/core';


@Injectable({
    providedIn: "root",
})
export class SVGService {
    constructor() { }

    makeSVGElement(tag, attrs) {
        var el = document.createElementNS('http://www.w3.org/2000/svg', tag);
        for (var k in attrs) {
            el.setAttribute(k, attrs[k]);
        }
        return el;
    }
    

}