import { Directive } from '@angular/core';

@Directive({
  selector: '[appGetHoursInFormat]'
})
export class GetHoursInFormatDirective {

  constructor() { }

  getHoursInFormat(Min) {
    var fHours = (Math.round(Min % 60) >= 60) ? Math.floor(Min / 60) + 1 : Math.floor(Min / 60);
    var fMin = (Math.round(Min % 60) >= 60) ? Math.round(Min % 60) - 60 : Math.round(Min % 60);
    var formattedHours = `${(fHours < 10) ? "0" + fHours : fHours}:${(fMin < 10) ? "0" + fMin : fMin}`;
    return formattedHours;
  }

}
