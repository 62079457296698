import { RouteInfo } from './sidebar.metadata';
export const ROUTES: RouteInfo[] = [


  // RoofTop Modules
  {
    path: '/rooftop/monitoring',
    title: 'Plant Monitoring',
    moduleName: 'monitoring',
    iconType: 'material-icons-two-tone',
    icon: 'monitor',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['User'],
    pCode:'rt',
    submenu: [],
  },
  {
    path: '/rooftop/dashboard',
    title: 'Command & Control Centre',
    moduleName: 'dashboard',
    iconType: 'material-icons-two-tone',
    icon: 'dashboard',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['User'],
    pCode:'rt',
    submenu: [],
  },
  {
    path: '/rooftop/performance',
    title: 'Performance Analytics',
    moduleName: 'PerformanceAnalysis',
    iconType: 'material-icons-two-tone',
    icon: 'assessment',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['User'],
    pCode:'rt',
    submenu: [],
  },
  {
    path: '/rooftop/operation-management',
    title: 'Operations Management',
    moduleName: 'rooftop',
    iconType: 'material-icons-two-tone',
    icon: 'fact_check',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['User'],
    pCode:'rt',
    submenu: [],
  },
  {
    path: '/rooftop/client-management',
    title: 'Client Management',
    moduleName: 'rooftop',
    iconType: 'material-icons-two-tone',
    icon: 'people_alt',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['User'],
    pCode:'rt',
    submenu: [],
  },
  {
    path: '/rooftop/project-management',
    title: 'Project Management',
    moduleName: 'rooftop',
    iconType: 'material-icons-two-tone',
    icon: 'local_library',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['User'],
    pCode:'rt',
    submenu: [],
  },
  {
    path: '/rooftop/site-management',
    title: 'Site Management',
    moduleName: 'rooftop',
    iconType: 'material-icons-two-tone',
    icon: 'map',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['User'],
    pCode:'rt',
    submenu: [],
  },
  {
    path: '/rooftop/asset-management',
    title: 'Asset Management',
    moduleName: 'rooftop',
    iconType: 'material-icons-two-tone',
    icon: 'widgets',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['User'],
    pCode:'rt',
    submenu: [],
  },
  {
    path: '',
    title: 'Reports',
    moduleName: 'reports',
    iconType: 'material-icons-two-tone',
    icon: 'description',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['User'],
    pCode: 'rt',
    submenu: [
      {
        path: '/',
        title: 'Single Parameter',
        moduleName: 'SpReports',
        iconType: '',
        icon: '',
        class: 'ml-sub-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['User'],
        pCode: 'rt',
        submenu: [
          {
            path: '/rooftop/reports/sp-reports/CUF',
            title: 'CUF (%)',
            moduleName: 'SpReports',
            iconType: '',
            icon: '',
            class: 'ml-sub-sub-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            role: ['User'],
            pCode: 'rt',
            submenu: [],
          },
          {
            path: '/rooftop/reports/sp-reports/PR',
            title: 'PR (%)',
            moduleName: 'SpReports',
            iconType: '',
            icon: '',
            class: 'ml-sub-sub-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            role: ['User'],
            pCode: 'rt',
            submenu: [],
          },
          {
            path: '/rooftop/reports/sp-reports/GEN',
            title: 'Generation ',
            moduleName: 'SpReports',
            iconType: '',
            icon: '',
            class: 'ml-sub-sub-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            role: ['User'],
            pCode: 'rt',
            submenu: [],
          },
          {
            path: '/rooftop/reports/sp-reports/Export',
            title: 'Export Energy ',
            moduleName: 'SpReports',
            iconType: '',
            icon: '',
            class: 'ml-sub-sub-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            role: ['User'],
            pCode: 'rt',
            submenu: [],
          },
          {
            path: '/rooftop/reports/sp-reports/EY',
            title: 'Energy Yield ',
            moduleName: 'SpReports',
            iconType: '',
            icon: '',
            class: 'ml-sub-sub-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            role: ['User'],
            pCode: 'rt',
            submenu: [],
          },
          {
            path: '/rooftop/reports/sp-reports/Availability',
            title: 'Availability ',
            moduleName: 'SpReports',
            iconType: '',
            icon: '',
            class: 'ml-sub-sub-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            role: ['User'],
            pCode: 'rt',
            submenu: [],
          },
          {
            path: '/rooftop/reports/sp-reports/GENHR',
            title: 'Generation Hours',
            moduleName: 'SpReports',
            iconType: '',
            icon: '',
            class: 'ml-sub-sub-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            role: ['User'],
            pCode: 'rt',
            submenu: [],
          },
        ],
      },
      {
        path: '/',
        title: 'Multi Parameter',
        moduleName: 'MpReports',
        iconType: '',
        icon: '',
        class: 'ml-sub-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['User'],
        pCode: 'rt',
        submenu: [
          {
            path: '/rooftop/reports/mp-reports/plant',
            title: 'Plant',
            moduleName: 'MpReports',
            iconType: '',
            icon: '',
            class: 'ml-sub-sub-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            role: ['User'],
            pCode: 'rt',
            submenu: [],
          },
          {
            path: '/rooftop/reports/mp-reports/inverter',
            title: 'Inverter',
            moduleName: 'MpReports',
            iconType: '',
            icon: '',
            class: 'ml-sub-sub-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            role: ['User'],
            pCode: 'rt',
            submenu: [],
          },
          {
            path: '/rooftop/reports/mp-reports/dcChannel',
            title: 'DCChannel',
            moduleName: 'MpReports',
            iconType: '',
            icon: '',
            class: 'ml-sub-sub-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            role: ['User'],
            pCode: 'rt',
            submenu: [],
          },
        ]
      },
      
    ],
  },
  {
    path: '/rooftop/user-role',
    title: 'User & Role Management',
    moduleName: 'rooftop',
    iconType: 'material-icons-two-tone',
    icon: 'supervised_user_circle',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['User'],
    pCode:'rt',
    submenu: [],
  },
  {
    path: '/rooftop/EDGE-RTU-Registration',
    title: 'EDGE RTU Registration',
    moduleName: 'deviceRegistration',
    iconType: 'material-icons-two-tone',
    icon: 'subtitles',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['User'],
    pCode:'rt',
    submenu: [],
  },


  //////////////////////////////////////////////////
  // MWSolar Modules

 
  {
    path: '/mwSolar/monitoring',
    title: 'Plant Monitoring',
    moduleName: 'monitoring',
    iconType: 'material-icons-two-tone',
    icon: 'monitor',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['User'],
    pCode:'mw',
    submenu: [],
  },
  {
    path: '/mwSolar/dashboard',
    title: 'Command & Control Centre',
    moduleName: 'dashboard',
    iconType: 'material-icons-two-tone',
    icon: 'dashboard',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['User'],
    pCode:'mw',
    submenu: [],
  },
  {
    path: '/mwSolar/performance',
    title: 'Performance Analytics',
    moduleName: 'PerformanceAnalysis',
    iconType: 'material-icons-two-tone',
    icon: 'assessment',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['User'],
    pCode:'mw',
    submenu: [],
  },
  {
    path: '/mwSolar/operation-management',
    title: 'Operations Management',
    moduleName: 'mwsolar',
    iconType: 'material-icons-two-tone',
    icon: 'fact_check',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['User'],
    pCode:'mw',
    submenu: [],
  },
  {
    path: '/mwSolar/client-management',
    title: 'Client Management',
    moduleName: 'mwSolar',
    iconType: 'material-icons-two-tone',
    icon: 'people_alt',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['User'],
    pCode:'mw',
    submenu: [],
  },
  {
    path: '/mwSolar/project-management',
    title: 'Project Management',
    moduleName: 'mwSolar',
    iconType: 'material-icons-two-tone',
    icon: 'local_library',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['User'],
    pCode:'mw',
    submenu: [],
  },
  {
    path: '/mwSolar/site-management',
    title: 'Site Management',
    moduleName: 'mwSolar',
    iconType: 'material-icons-two-tone',
    icon: 'map',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['User'],
    pCode:'mw',
    submenu: [],
  },
  {
    path: '/mwSolar/asset-management',
    title: 'Asset Management',
    moduleName: 'mwsolar',
    iconType: 'material-icons-two-tone',
    icon: 'widgets',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['User'],
    pCode:'mw',
    submenu: [],
  },
  {
    path: '',
    title: 'Reports',
    moduleName: 'reports',
    iconType: 'material-icons-two-tone',
    icon: 'description',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['User'],
    pCode: 'mw',
    submenu: [
      {
        path: '/',
        title: 'Single Parameter',
        moduleName: 'SpReports',
        iconType: '',
        icon: '',
        class: 'ml-sub-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['User'],
        pCode: 'mw',
        submenu: [
          {
            path: '/mwSolar/reports/sp-reports/cuf-report',
            title: 'CUF (%)',
            moduleName: 'SpReports',
            iconType: '',
            icon: '',
            class: 'ml-sub-sub-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            role: ['User'],
            pCode: 'mw',
            submenu: [],
          },
          {
            path: '/mwSolar/reports/sp-reports/pr-report',
            title: 'PR (%)',
            moduleName: 'SpReports',
            iconType: '',
            icon: '',
            class: 'ml-sub-sub-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            role: ['User'],
            pCode: 'mw',
            submenu: [],
          },
          {
            path: '/mwSolar/reports/sp-reports/generetion-report',
            title: 'Generation ',
            moduleName: 'SpReports',
            iconType: '',
            icon: '',
            class: 'ml-sub-sub-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            role: ['User'],
            pCode: 'mw',
            submenu: [],
          },
          {
            path: '/mwSolar/reports/sp-reports/export-enegry-report',
            title: 'Export Energy ',
            moduleName: 'SpReports',
            iconType: '',
            icon: '',
            class: 'ml-sub-sub-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            role: ['User'],
            pCode: 'mw',
            submenu: [],
          },
          {
            path: '/mwSolar/reports/sp-reports/energy-yield-report',
            title: 'Enegry Yield ',
            moduleName: 'SpReports',
            iconType: '',
            icon: '',
            class: 'ml-sub-sub-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            role: ['User'],
            pCode: 'mw',
            submenu: [],
          },
          {
            path: '/mwSolar/reports/sp-reports/availability-report',
            title: 'Availability ',
            moduleName: 'SpReports',
            iconType: '',
            icon: '',
            class: 'ml-sub-sub-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            role: ['User'],
            pCode: 'mw',
            submenu: [],
          },
          {
            path: '/mwSolar/reports/sp-reports/generation-hours-report',
            title: 'Generation Hours',
            moduleName: 'SpReports',
            iconType: '',
            icon: '',
            class: 'ml-sub-sub-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            role: ['User'],
            pCode: 'mw',
            submenu: [],
          },
        ],
      },
      {
        path: '/',
        title: 'Multi Parameter',
        moduleName: 'MpReports',
        iconType: '',
        icon: '',
        class: 'ml-sub-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['User'],
        pCode: 'mw',
        submenu: [
          {
            path: '/mwSolar/reports/mp-reports/portfolio-report',
            title: 'Portfolio',
            moduleName: 'MpReports',
            iconType: '',
            icon: '',
            class: 'ml-sub-sub-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            role: ['User'],
            pCode: 'mw',
            submenu: [],
          },
          {
            path: '/mwSolar/reports/mp-reports/plant-report',
            title: 'Plant',
            moduleName: 'MpReports',
            iconType: '',
            icon: '',
            class: 'ml-sub-sub-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            role: ['User'],
            pCode: 'mw',
            submenu: [],
          },
          {
            path: '/mwSolar/reports/mp-reports/block-report',
            title: 'Block',
            moduleName: 'MpReports',
            iconType: '',
            icon: '',
            class: 'ml-sub-sub-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            role: ['User'],
            pCode: 'mw',
            submenu: [],
          },
          {
            path: '/mwSolar/reports/mp-reports/inverter-report',
            title: 'Inverter',
            moduleName: 'MpReports',
            iconType: '',
            icon: '',
            class: 'ml-sub-sub-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            role: ['User'],
            pCode: 'mw',
            submenu: [],
          },
        ]
      },
      
    ],
  },
  {
    path: '/mwSolar/user-role',
    title: 'User & Role Management',
    moduleName: 'mwsolar',
    iconType: 'material-icons-two-tone',
    icon: 'supervised_user_circle',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['User'],
    pCode:'mw',
    submenu: [],
  },
  {
    path: '/mwSolar/EDGE-RTU-Registration',
    title: 'EDGE RTU Registration',
    moduleName: 'deviceRegistration',
    iconType: 'material-icons-two-tone',
    icon: 'subtitles',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['User'],
    pCode:'mw',
    submenu: [],
  }

  

];
