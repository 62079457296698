import Map from "ol/Map";
import View from "ol/View";
import { Icon, Text, Fill, Stroke, Style, Circle } from "ol/style";
import Point from "ol/geom/Point";
import Feature from "ol/Feature";
import LayerTile from "ol/layer/Tile";
import ZoomToExtent from "ol/control/ZoomToExtent";
import FullScreen from "ol/control/FullScreen";
import ScaleLine from "ol/control/ScaleLine";
import Attribution from "ol/control/Attribution";
import SourceOsm from "ol/source/OSM";
import SourceStamen from "ol/source/Stamen";
import { fromLonLat } from "ol/proj";
import { defaults as defaultControls } from "ol/control";
import { defaults as defaultInteractions, PinchZoom } from "ol/interaction";
import { Injectable } from "@angular/core";
import Overlay from "ol/Overlay";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import * as proj from 'ol/proj'
import { toLonLat, transform } from "ol/proj";
import { toStringHDMS } from "ol/coordinate";
import GeoJSON from "ol/format/GeoJSON";
import BaseLayer from "ol/layer/Base";
// import transform from "ol/proj";

/**
 * Elements that make up the popup.
 */
// const container = document.getElementById("popup");
// const content = document.getElementById("popup-content");
// const closer = document.getElementById("popup-closer");

/**
 * Create an overlay to anchor the popup to the map.
 */
// const overlay = new Overlay({
//   element: document.getElementById("popup"),
//   autoPan: true,
//   autoPanAnimation: {
//     duration: 250,
//   },
// });

/**
 * Add a click handler to hide the popup.
 * @return {boolean} Don't follow the href.
 */
//  document.getElementById("popup-closer").onclick = ()=> {
//   overlay.setPosition(undefined);
//   closer.blur();
//   return false;
// };

/**
 * Geographical map containing some basic controls and two sources for its base layer.
 */
@Injectable({
  providedIn: "root",
})
export class GeoService {
  /** OL-Map. */
  readonly map: Map;

  /** Basic layer. */
  readonly layerTile: LayerTile;
  readonly markerTile: VectorLayer;
  readonly geoJSONTile: VectorLayer;
  readonly exceptIndia_layer: VectorLayer;

  /** Sources for basic layer. */
  readonly sources: { readonly osm: SourceOsm; readonly stamen: SourceStamen };

  /**
   * Initialise the map.
   */

  //  markerLayer:BaseLayer[]=[];
  // geojson_layer = [];
  // exceptIndia_layer = [];

  constructor() {

    var styles = {
      Point: [
        new Style({
          image: new Circle({
            radius: 8,
            fill: new Fill({
              color: [255, 255, 255, 0.3],
            }),
            stroke: new Stroke({ color: "#cb1d1d", width: 2 }),
          }),
        }),
      ],
      LineString: [
        new Style({
          stroke: new Stroke({
            color: "green",
            width: 1,
          }),
        }),
      ],
      Polygon: [
        new Style({
          stroke: new Stroke({
            color: "blue",
            lineDash: [4],
            width: 3,
          }),
          fill: new Fill({
            color: "#fff",
          }),
        }),
      ],
      Circle: [
        new Style({
          stroke: new Stroke({
            color: "red",
            width: 2,
          }),
          fill: new Fill({
            color: "rgba(255,0,0,0.2)",
          }),
        }),
      ],
      MultiPolygon: [
        new Style({
          stroke: new Stroke({
            color: "blue",
          }),
          fill: new Fill({
            color: "#abc12350",
          }),
        }),
      ],
    };
    this.markerTile = this.setMap(this.locations);
    this.sources = {
      osm: new SourceOsm(),
      stamen: new SourceStamen({ layer: "toner" }),
    };

    this.layerTile = new LayerTile({
      source: this.sources.osm,
    });
    var styleFunction = function (feature, resolution) {
      return styles[feature.getGeometry().getType()];
    };
    this.geoJSONTile = new VectorLayer({
      source: new VectorSource({
        url: "./assets/data/IndiaJson.geojson",
        format: new GeoJSON(),
      }),
      style: styleFunction,
    });
    this.exceptIndia_layer = new VectorLayer({
      source: new VectorSource({
        url: "./assets/data/exceptIndia.geojson",
        format: new GeoJSON(),
      }),
      style: styleFunction,
    });
    this.map = new Map({
      interactions: defaultInteractions().extend([new PinchZoom()]),
      layers: [this.layerTile, this.markerTile, this.geoJSONTile, this.exceptIndia_layer],
      view: new View({
        center: fromLonLat([77.694921, 12.455351]),
        zoom: 4.5,
        minZoom: 4.5,
        // maxZoom:4.5,
        constrainResolution: true,
      }),
      controls: defaultControls().extend([
        new Attribution(),
        new ZoomToExtent({
          extent: [
            813079.7791264898, 5929220.284081122, 848966.9639063801,
            5936863.986909639,
          ],
        }),
        new FullScreen(),
        new ScaleLine({
          bar: true,
          minWidth: 150,
        }),
      ]),
    });
    const popup = new Overlay({
      element: document.getElementById('popup'),
    });
    this.map.addOverlay(popup);
    /**
     * Add a click handler to the map to render the popup.
     */
    this.map.on("singleclick", (evt) => {
      // this.map.getView().setZoom(this.map.getView().getZoom() + 1);
      // this.map.getView().setCenter(transform([72.5714, 23.0225]))
      // var name = this.map.forEachFeatureAtPixel(evt.pixel, function (feature) {
      //   return feature.get("name");
      // });
      // if (name) {
      //   document.getElementById("popup").style.display = "block";
      //   var coordinate = evt.coordinate;
      //   document.getElementById("popup-content").innerHTML = `<a (click)='markerClick(${name})'>${name}</a>`;
      //   overlay.setPosition(coordinate);
      // } else {
      //   document.getElementById("popup").style.display = "none";
      // }
      // this.map.getView().setCenter(transform([72.5714,23.0225]))
      // this.map.getView().setZoom(1)
      this.map.getView().setCenter(fromLonLat([72.5714, 23.0225]));
      this.map.getView().setZoom(12);
      this.map.removeLayer(this.markerTile);
      var innerMarker = this.setMap(this.locations[0][4]);
      this.map.addLayer(innerMarker)
    });
  }

  locations = [
    [
      "Ahmedabad",
      23.0225,
      72.5714,
      "green",
      [
        ["a", 23.004478, 72.604809, "green"],
        ["b", 23.071270, 72.619236, "green"],
        ["c", 23.001018, 72.544342, "green"],
      ],
    ],
    // [
    //   "Coogee Beach",
    //   -33.923036,
    //   151.259052,
    //   "blue",
    //   [
    //     ["a", -33.922288, 151.256445, "blue"],
    //     ["b", -33.921647, 151.255302, "blue"],
    //     ["c", -33.920628, 151.256938, "blue"],
    //   ],
    // ],
    // ["Cronulla Beach", -34.028249, 151.157507, "yellow"],
    // ["Manly Beach", -33.80010128657071, 151.28747820854187, "purple"],
    // [
    //   "Maroubra Beach",
    //   -33.950198,
    //   151.259302,
    //   "red",
    //   [
    //     ["a", -33.952014, 151.255461, "red"],
    //     ["b", -33.951422, 151.25236, "red"],
    //     ["c", -33.948374, 151.25531, "red"],
    //   ],
    // ],
  ];

  setMap(locations) {
    var features = [];
    for (var i = 0; i < locations.length; i++) {
      features.push(
        this.coloredSvgMarker(
          [locations[i][2], locations[i][1]],
          locations[i][0],
          locations[i][3]
        )
      );
    }
    var vectorSource = new VectorSource({
      // VectorSource({
      features: features,
    });

    var vectorLayer = new VectorLayer({
      // VectorLayer({
      source: vectorSource,
    });
    return vectorLayer;
  }

  markerClick(markerName: any) {
    debugger
    console.log(markerName);
  }

  /**
   * Sets the view to the accordant zoom and center.
   *
   * @param zoom Zoom.
   * @param center Center in long/lat.
   */
  setView(zoom: number, center: [number, number]) {
    this.map.getView().setZoom(10);
    this.map.getView().setCenter(fromLonLat(center));
  }

  /**
   * Updates target and size of the map.
   *
   * @param target HTML container.
   */
  updateSize(target = "map") {
    this.map.setTarget(target);
    this.map.updateSize();
  }

  /**
   * Sets the source of the base layer.
   *
   * @param source Source.
   */
  setSource(source: "osm" | "stamen") {
    this.layerTile.setSource(this.sources[source]);
  }

  coloredSvgMarker(lonLat, name, color, circleFill = "white") {
    if (!color) color = "red";
    if (!circleFill) circleFill = "white";
    var feature = new Feature({
      geometry: new Point(fromLonLat(lonLat)),
      name: name,
    });
    var svg =
      '<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="30px" height="30px" viewBox="0 0 30 30" enable-background="new 0 0 30 30" xml:space="preserve">' +
      '<path fill="' +
      color +
      '" d="M22.906,10.438c0,4.367-6.281,14.312-7.906,17.031c-1.719-2.75-7.906-12.665-7.906-17.031S10.634,2.531,15,2.531S22.906,6.071,22.906,10.438z"/>' +
      '<circle fill="' +
      circleFill +
      '" cx="15" cy="10.677" r="3.291"/></svg>';

    feature.setStyle(
      new Style({
        image: new Icon({
          anchor: [0.5, 1.0],
          // anchorXUnits: "fraction",
          // anchorYUnits: "fraction",
          src: "data:image/svg+xml," + escape(svg),
          scale: 2,
          imgSize: [30, 30],
        }),
        text: new Text({
          text: "Test Text",
          scale: 1.2,
          fill: new Fill({
            color: "#fff",
          }),
          stroke: new Stroke({
            color: "0",
            width: 3,
          }),
        }),
      })
    );
    return feature;
  }
}
