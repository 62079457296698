import { Injectable } from '@angular/core';
import { Layer } from '../models/layer.enum';

@Injectable({
    providedIn: "root",
})
export class UtilityService {
    constructor() { }

    getBgClass(selectedParam: string, value: number) {
        switch (selectedParam) {
            case "CUF":
                if (value < 16) {
                    return "bg-blue5";
                } else if (value >= 16 && value < 18) {
                    return "bg-blue4";
                } else if (value >= 18 && value < 20) {
                    return "bg-blue3";
                } else if (value >= 20 && value < 22) {
                    return "bg-blue2";
                } else if (value >= 22) {
                    return "bg-blue1";
                }
                break;
            case "PR":
                if (value < 70) {
                    return "bg-green5";
                } else if (value >= 70 && value < 75) {
                    return "bg-green4";
                } else if (value >= 75 && value < 80) {
                    return "bg-green3"
                } else if (value >= 80 && value < 85) {
                    return "bg-green2";
                } else if (value >= 85) {
                    return "bg-green1";
                }
                break;
            case "SG":
                if (value < 2) {
                    return "bg-purple5";
                } else if (value >= 2 && value < 3) {
                    return "bg-purple4";
                } else if (value >= 3 && value < 4) {
                    return "bg-purple3"
                } else if (value >= 4 && value < 5) {
                    return "bg-purple2";
                } else if (value >= 5) {
                    return "bg-purple1";
                }
                break;

            default:
                break;
        }
    }

    getGridBgClass(selectedParam: string, value: number) {
        switch (selectedParam) {
            case "CUF":
                if (value < 16) {
                    return "cufBG-1";
                } else if (value >= 16 && value < 18) {
                    return "cufBG-2";
                } else if (value >= 18 && value < 20) {
                    return "cufBG-3";
                } else if (value >= 20 && value < 22) {
                    return "cufBG-4";
                } else if (value >= 22) {
                    return "cufBG-5";
                }
                break;
            case "PR":
                if (value < 75) {
                    return "prBG-1";
                } else if (value >= 70 && value < 75) {
                    return "prBG-2";
                } else if (value >= 75 && value < 80) {
                    return "prBG-3"
                } else if (value >= 80 && value < 85) {
                    return "prBG-4";
                } else if (value >= 85) {
                    return "prBG-5";
                }
                break;
            case "SG":
                if (value < 2) {
                    return "sgBG-1";
                } else if (value >= 2 && value < 3) {
                    return "sgBG-2";
                } else if (value >= 3 && value < 4) {
                    return "sgBG-3"
                } else if (value >= 4 && value < 5) {
                    return "sgBG-4";
                } else if (value >= 5) {
                    return "sgBG-5";
                }
                break;

            default:
                break;
        }
    }

    getMapIcon(CUF, PR, Availability) {
        var CUFClass = "", PRClass = "", AvailableClass = "";
        if (CUF < 16) {
            CUFClass = "blue5";
        } else if (CUF >= 16 && CUF < 18) {
            CUFClass = "blue4";
        } else if (CUF >= 18 && CUF < 20) {
            CUFClass = "blue3";
        } else if (CUF >= 20 && CUF < 22) {
            CUFClass = "blue2";
        } else if (CUF >= 22) {
            CUFClass = "blue1";
        }
        if (PR > 85) {
            PRClass = "black";
        } else {
            PRClass = "white";
        }

        if (Availability.toLowerCase() == Layer.Plant) {
            AvailableClass = "red1";
        } else if (Availability.toLowerCase() == Layer.Block) {
            AvailableClass = "orange1";
        } else if (Availability.toLowerCase() == Layer.Inverter) {
            AvailableClass = "orange2";
        } else if (Availability.toLowerCase() == Layer.DCChannel) {
            AvailableClass = "yellow";
        }else{
            AvailableClass="no";
        }
        return `${CUFClass}-${AvailableClass}-${PRClass}`;
    }
}