<div class="text-end margin-20">
  <button mat-raised-button class="l-bg-red"  (click)="logout()"> <i class="material-icons col-white">power_settings_new</i> <span class="m-l-20">Logout</span></button>
</div>

<div class="d-flex align-items-center justify-content-center">
    <div class="card" style="width: 22rem">
      <img
        class="card-img-top"
        src="../../assets/images/rooftop-2.jpg"
        alt="Card image cap"
      />
      <div class="card-body text-center">
        <h5 class="card-title">Rooftop Solar Plants</h5>
        <h6>&nbsp;</h6>
        <!-- <p class="card-text">
          Some quick example text to build on the card title and make up the bulk
          of the card's content.
        </p> -->
        <!-- //////////////////// -->
        <div class="row homeCard">
        <div class="col-7 padding-0">
          <label class="font-14 demo-google-material-icon">Total Installation</label>
          <h4 class="col-blue">
              <span>5</span> <span class="m-l-10 m-r-10"> | </span>  <span class="">29120</span> 
          </h4>
          <span class="col-blue">
              <span class="font-12 float-start  m-l-25">Nos.</span>
              <span class="font-12">kW</span>
          </span>
        </div>
        <div class="col-5 padding-0">
          <label class="font-14 demo-google-material-icon">Total Generation</label>
          <h4 class="col-green">
               <span class="">290</span> 
          </h4>
          <span class="col-green">
              <span class="font-12">kWh</span>
          </span>
        </div>
      </div>
        <button mat-raised-button class="l-bg-blue"  (click)="selectedProject('rt') "> <i class="material-icons col-white">open_in_browser</i> <span class="m-l-10">View Portfolio</span></button>
        <!-- <a class="btn btn-primary" [routerLink]="['/rooftop']" (click)="selectedProject('rt')">Go To RoofTop Portal</a> -->
      </div>
    </div>
  
    <div class="card" style="width: 22rem">
      <img
        class="card-img-top"
        src="../../assets/images/plant-2.png"
        alt="Card image cap"
      />
      <div class="card-body text-center">
        <h5 class="card-title">Utility Scale Solar Plants</h5>
        <h6>(Ground Mount & Rooftop)</h6>
        <div class="row homeCard">
          <div class="col-7 padding-0">
            <label class="font-14 demo-google-material-icon">Total Installation</label>
            <h4 class="col-blue">
                <span>5</span> <span class="m-l-10 m-r-10"> | </span>  <span class="">280.5</span> 
            </h4>
            <span class="col-blue">
              <span class="font-12 float-start  m-l-25">Nos.</span>
              <span class="font-12">MW</span>
            </span>
          </div>
          <div class="col-5 padding-0">
            <label class="font-14 demo-google-material-icon">Total Generation</label>
            <h4 class="col-green">
                 <span class="">40156.17</span> 
            </h4>
            <span class="col-green">
                <span class="font-12">MWh</span>
            </span>
          </div>
        </div>
        <!-- <p class="card-text">
          Some quick example text to build on the card title and make up the bulk
          of the card's content.
        </p> -->
        <button mat-raised-button class="l-bg-blue"  (click)="selectedProject('mw') "> <i class="material-icons col-white">open_in_browser</i> <span class="m-l-10">View Portfolio</span></button>
        <!-- <a class="btn btn-primary" [routerLink]="['/mwSolar']" (click)="selectedProject('mw')">Go To MW Solar Portal</a> -->
      </div>
    </div>
   
  </div>
  