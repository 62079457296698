import { Injectable } from '@angular/core';
import { BehaviorSubject, filter, of } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { IPlant } from '../models/plant.model';
import { Layer } from '../models/layer.enum';
@Injectable({
    providedIn: "root",
})
export class PlantMonitoringService {
    private plantsSubject: BehaviorSubject<IPlant[]> = new BehaviorSubject([]);
    plants = this.plantsSubject.asObservable();
    plantList: IPlant[] = [];
    selectedPlant: IPlant = {
        CUF: 0,
        PR: 0,
        nonAvailability: 0,
        LId: 0,
        LName: '',
        LType: '',
        PSize: 0,
        PPARate: 0,
        PRThreshold: 0,
        Identifier: 0,
        ParentId: 0,
        MParentId: 0,
        DeptId: 0,
        SubNockId: 0,
        ACCap: 0,
        DCCap: 0,
        Addr: '',
        Lat: '',
        Long: '',
        NationId: '',
        StateId: '',
        District: '',
        City: '',
        Taluka: '',
        LCoverage: '',
        SG: 0,
        AC: 0,
        DC: 0,
        Status: "",
        TG: 0,
        Import: 0,
        Export: 0,
        AlStatus: 0,
        GenHR: '',
        ACEnergy: 0,
        DCEnergy: 0,
        CO2: 0,
        Trees: 0,
        Revenue: 0,
        POA: 0,
        Insolation: 0,
        GHI: 0,
        ModuleTemp: 0,
        AmbientTemp: 0,
        Humidity: 0,
        WindSpeed: 0,
        WindDirection: 0,
        SolarPower: '',
        pImport: '',
        pExport: ''
    };
    constructor(private http: HttpClient) {
        this.getPlantsDetails();
    }

    private getPlantsDetails() {
        if (this.plantList.length > 0)
            return of(this.plantList);
        this.http.get<IPlant[]>('./assets/data/PlantData.json').subscribe(data => {
            this.plantList = data;
            this.plantsSubject.next(data);
        })
    }
    getBlocks() {
        return  this.http.get<IPlant[]>('./assets/data/PlantData.json');
        // this.plantList.filter(el => el.LType.toLowerCase() == Layer.Block);
    }
    getAllACDB() {
        // return this.plantList.filter(el => el.LType.toLowerCase() == Layer.ACDB);
        return  this.http.get<IPlant[]>('./assets/data/PlantData.json');
    }
    getAllInverters() {
        // return this.plantList.filter(el => el.LType.toLowerCase() == Layer.Inverter);
        return  this.http.get<IPlant[]>('./assets/data/PlantData.json');
    }
    getAllDCChannels() {
        // return this.plantList.filter(el => el.LType.toLowerCase() == Layer.DCChannel);
        return  this.http.get<IPlant[]>('./assets/data/PlantData.json');
    }
    getBlockByPlant(plantId){
        debugger
        console.log('this.plant', this.plantList)
        return this.plantList.filter(el => el.LType.toLowerCase() == Layer.Block && el.ParentId == plantId);
    }
    getInverterByControlRoom(controlRoomId) {
        var acdb = this.plantList.filter(el => el.LType.toLowerCase() == Layer.ACDB && el.ParentId == controlRoomId).map(el => el.LId);
        return this.plantList.filter(el => el.LType.toLowerCase() == Layer.Inverter && acdb.indexOf(el.ParentId) != -1);
    }

    getDCChannelByInverter(inverterId) {
        return this.plantList.filter(el => el.LType.toLowerCase() == Layer.DCChannel && el.ParentId == inverterId);
    }

}
