import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerModule } from "ngx-spinner";

import { MaterialModule } from "./material.module";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { HttpClientModule } from "@angular/common/http";
import { NgApexchartsModule } from "ng-apexcharts";
import { NgxEchartsModule } from 'ngx-echarts';
import { GaugeModule } from 'angular-gauge';
import { NgxDaterangepickerMd } from "ngx-daterangepicker-material";
const shareModules = [
  FormsModule,
  ReactiveFormsModule,
  RouterModule,
  NgbModule,
  NgxSpinnerModule,
  NgxDatatableModule,
  PerfectScrollbarModule,
  HttpClientModule,
  NgApexchartsModule,
  GaugeModule.forRoot(),
  NgxEchartsModule.forRoot({
    echarts: () => import('echarts'),
  }),
  NgxDaterangepickerMd.forRoot()
];
@NgModule({
  declarations: [],
  imports: [CommonModule, shareModules],
  exports: [CommonModule, shareModules, MaterialModule],
})
export class SharedModule {}
