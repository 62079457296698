import { Directive } from '@angular/core';
import * as moment from 'moment';

@Directive({
  selector: '[appGetDate]'
})
export class GetDateDirective {

  constructor() { }

  getDates(startDate, stopDate) {
    var dateArray = new Array();
    var currentDate = startDate;
    while (currentDate <= stopDate) {
      dateArray.push(currentDate);
      currentDate = moment(currentDate).add(1, "d");
    }
    dateArray = dateArray.map((el) => {
      return moment(el).format("DD-MM-YYYY");
    });
    return dateArray;
  }

}
