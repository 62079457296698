import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RightSidebarService } from './service/rightsidebar.service';
import { AuthGuard } from './guard/auth.guard';
import { AuthService } from './service/auth.service';
import { DynamicScriptLoaderService } from './service/dynamic-script-loader.service';
import { throwIfAlreadyLoaded } from './guard/module-import.guard';
import { PlantMonitoringService } from "./service/plant-monitoring.service";
import { UtilityService } from "./service/utility.service";
import { GetDateDirective } from './directive/get-date.directive';
import { GetHoursInFormatDirective } from './directive/get-hours-in-format.directive';
import { GeoService } from "./service/geo.service";
import { RooftopMonitoringService } from './service/rooftop-monitoring.service';
import { SVGService } from './service/svg.service';
@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    RightSidebarService,
    AuthGuard,
    AuthService,
    DynamicScriptLoaderService,
    PlantMonitoringService,
    RooftopMonitoringService,
    UtilityService,
    GetDateDirective,
    GetHoursInFormatDirective,
    GeoService, SVGService
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
