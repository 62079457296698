import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../core/service/auth.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  PCode;
  constructor(private authService: AuthService, private router: Router) { }

  ngOnInit(): void {
    // this.PCode = localStorage.getItem('pCode');
  }

  selectedProject(PCode: string) {
    localStorage.setItem("pCode", PCode);
    if(PCode == 'rt'){
      this.router.navigate(['/rooftop/monitoring']);
    }else if(PCode == 'mw'){
      this.router.navigate(['/mwSolar/monitoring']);
    }else {
      this.router.navigate(['/authentication/signin']);
    }
   
  }

  logout() {
    this.authService.logout().subscribe((res) => {
      if (!res.success) {
        this.router.navigate(['/authentication/signin']);
      }
    });
  }
}
